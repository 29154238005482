export const HOME_SLIDER_ITEM = [
  {
    image: require("../images/main-slider/slider1/bannerHome-1.jpeg"),
    title: "Polystone Homopolymer",
    description:
      "Kekuatan Tinggi, Sifat Pemrosesan yang sangat baik, Ketahana Kimia, Penghambat Api",
  },
  {
    image: require("../images/main-slider/slider1/bannerHome-2.jpeg"),
    title: "Welding Rods.",
    description: "Batang Las Plastik terbuat dari PE, PP, PVC, PVDF.",
  },
  {
    image: require("../images/main-slider/slider1/bannerHome-3.jpeg"),
    title: "SustaPVDF natural.",
    description:
      "Kemurnian tinggi, Kekuatan benturan dingin yang tinggi, Kemampuan las sangat baik, sifat isolasi listrik yang baik, Ketahanan kimia.",
  },
];
