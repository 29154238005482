import React from "react";
import { NavLink } from "react-router-dom";

class Navigation extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     url: "product",
  //   };
  // }
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("/assets/js/masonary.js");
  }

  render() {
    return (
      <React.Fragment>
        <div
          className={
            this.props.bgcolor !== ""
              ? `header-nav navbar-collapse collapse ${this.props.bgcolor}`
              : "header-nav navbar-collapse collapse"
          }
        >
          <ul className="nav navbar-nav">
            <li>
              <NavLink to={"/"}>Beranda</NavLink>
            </li>
            <li>
              <NavLink to={"/product"}>produk</NavLink>
            </li>
            <li>
              <NavLink to={"/about"}>Tentang Kami</NavLink>
            </li>
            {/* <li>
              <NavLink to={"/contactus"}>Hubungi Kami</NavLink>
            </li> */}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default Navigation;
