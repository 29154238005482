import React, { Component } from "react";

const services = [
  {
    count: 1,
    title: `Bidang Perusahaan`,
    icon: require("./../../images/icon/crane-1.png"),
    classIcon: "fa fa-building",
    description:
      "MC Nylon Sheet, Pipa PTFE, PP, Teflon Lembaran, PVC Lembaran.",
  },
  {
    count: 2,
    title: "Tahun Terdaftar",
    icon: require("./../../images/icon/renovation.png"),
    classIcon: "fa fa-pencil-square-o",
    description: "2000",
  },
  {
    count: 3,
    title: "Jumlah Karyawan",
    icon: require("./../../images/icon/toolbox.png"),
    classIcon: "fa fa-users",
    description: "20",
  },
  {
    count: 4,
    title: "Architecture Design",
    icon: require("./../../images/icon/compass.png"),
    classIcon: "fa fa-cart-arrow-down",
    description:
      "Engineering Plastik, PVC Sheet / Curtain, MC Nylon / HDPE, PTFE / Teflon Sheet, POM, UHMWPE / PE 1000",
  },
];

export default class ServicesAndSpecialized extends Component {
  render() {
    return (
      <div className="row">
        {services.map((item, index) => (
          <div
            className="col-md-3 col-sm-6"
            key={index}
            style={{ minHeight: "400px" }}
          >
            <div className="mt-icon-box-wraper m-b30">
              <div className="relative icon-count-2 bg-gray p-a30 p-tb50">
                <span className="icon-count-number">{item.count}</span>
                <div className="icon-md inline-icon m-b15 text-primary scale-in-center">
                  <span className="icon-cell">
                    <i className={item.classIcon}></i>
                  </span>
                </div>
                <div className="icon-content">
                  <h4 className="mt-tilte m-b25">{item.title}</h4>
                  <p>{item.description}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
