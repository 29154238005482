import React from "react";
import ServicesAndSpecialized from "./ServicesAndSpecialized";

var img1 = require("./../../images/background/bg-6.png");

class OurServices extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/masonary.js");
  }
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding  p-b50  square_shape2">
          <div className="section-content">
            <div
              className="Service-half-top p-t80  bg-dark bg-moving"
              style={{ backgroundImage: "url(" + img1 + ")" }}
            >
              <div className="container">
                {/* TITLE START */}
                <div className="section-head text-white">
                  <div className="mt-separator-outer separator-left">
                    <div className="mt-separator">
                      <h2 className="text-white text-uppercase sep-line-one ">
                        <span className="font-weight-300 text-primary">
                          Our
                        </span>{" "}
                        Services
                      </h2>
                    </div>
                  </div>
                  <h3>
                    Kami menyediakan berbagai macam produk plastik industri
                    berkualitas tinggi untuk memenuhi kebutuhan bisnis Anda.
                  </h3>
                </div>
                {/* TITLE END */}
              </div>
            </div>
            <div className="services-half-bottom">
              <ServicesAndSpecialized />
            </div>
          </div>
          <div className="hilite-title text-left p-l50 text-uppercase text-pop-up-top">
            <strong>Services</strong>
          </div>
        </div>
      </>
    );
  }
}

export default OurServices;
