import React from "react";
import { NavLink } from "react-router-dom";

var bnr1 = require("./../../images/background/bg-5.png");

class Specialization extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full mobile-page-padding bg-white p-t80 p-b30 bg-repeat square_shape1"
          style={{ backgroundImage: "url(" + bnr1 + ")" }}
        >
          <div className="container">
            {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row">
                <div className="col-md-12 m-b30">
                  <div className="mt-box our-speciallization-content">
                    <h2 className="m-t0">
                      {/* <span className="font-weight-100">Building</span> <br /> */}
                      Rochling Hadirkan Solusi Plastik Rekayasa Unggul
                    </h2>
                    <p>
                      Dunia manufaktur terus bertransformasi dengan pesat.
                      Permintaan akan material yang kuat, ringan, dan tahan lama
                      semakin meningkat. Di sinilah Röchling hadir sebagai
                      solusi. Sebagai pemimpin global dalam bidang plastik
                      rekayasa, Röchling menawarkan berbagai macam produk yang
                      dirancang untuk memenuhi kebutuhan industri yang paling
                      menuntut.
                    </p>
                    <NavLink to="/product" className="site-button btn-effect">
                      View All
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hilite-title text-right p-r50 text-uppercase text-pop-up-top">
            <strong>Welcome</strong>
          </div>
        </div>
      </>
    );
  }
}

export default Specialization;
