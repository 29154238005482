import React from "react";
import { NavLink } from "react-router-dom";

export const ProductDetailFooterSosmed = () => {
  return (
    <ul className="social-icons social-square social-darkest m-b0">
      <li>
        <NavLink to={"#"} className="fa fa-facebook" />
      </li>
      <li>
        <NavLink to={"#"} className="fa fa-twitter" />
      </li>
      <li>
        <NavLink to={"#"} className="fa fa-linkedin" />
      </li>
      <li>
        <NavLink to={"#"} className="fa fa-rss" />
      </li>
      <li>
        <NavLink to={"#"} className="fa fa-youtube" />
      </li>
      <li>
        <NavLink to={"#"} className="fa fa-instagram" />
      </li>
    </ul>
  );
};
