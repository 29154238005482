export const PRODUCTS = [
  {
    slug: "pp-rochling-grey",
    thumb: require("./../../images/product/product1.webp"),
    image: require("./../../images/product/product1.webp"),
    title: "PP Rochling Grey",
    description: [
      "Polystone®P homopolymer materials are extremely strong and highly resistant to chemicals, corrosion and heat.",
      {
        title: "Special properties",
        detail: [
          "High rigidity",
          "Very good weldability",
          "High chemical and corrosion resistance",
        ],
      },
      {
        title: "Typical areas of application",
        detail: [
          "Chemical engineering and tank building",
          "Ventilation technology",
          "Pump engineering",
        ],
      },
      {
        title: "Type of product",
        detail: [
          "coils",
          "compression moulded sheets",
          "extruded sheets",
          "round rods",
          "square tubes",
          "u profiles",
          "welding rods",
        ],
      },
      {
        title: "Colors",
        detail: [
          "black",
          "grey",
          "grey-marble",
          "light-grey",
          "natural",
          "special colours",
          "white",
        ],
      },
      "Polypropylene Homopolymer",
    ],
    filter: "cat-1",
    price: 1234567,
    min_purchase: 1,
    unit: "Role",
  },
  {
    slug: "pp-rochling-sheet",
    thumb: require("./../../images/product/product2.webp"),
    image: require("./../../images/product/product2.webp"),
    title: "Plastik PP Rochling (Sheet rod and welding ROD)",
    description: [
      "PP Rochling",
      "plastik komoditas disamping polyethylene (PE) dan polyvinylchloride (PVC). Namun PP memiliki kombinasi nilai properties fisik, mekanikal, thermal dan electrical yang sangat baik dibandingkan material plastik lainnya.",
      "Dibandingkan LDPE (Low Density Poly-Ethylen) ataupun HDPE (High Density Poly-Ethylen), PP memiliki impact strength yang lebih rendah namun memiliki tensile strength yang lebih tinggi dan juga lebih tahan terhadap temperature yang lebih tinggi (100ᵒC) dibanding PE (90ᵒC).",
      "PP memiliki ketahanan kimia yang sangat baik, tahan terhadap asam, basa, pelarut organic, degreasing agent. PP adalah plastik yang paling ringan BJ 0,9 dan daya serap air yang sangat rendah dibanding hampir semua jenis plastik lainnya. Bersifat non polar, sehingga sulit untuk ternoda (staining), dicat (painting), dan dilem (gluing).",
      "ROCHLING adalah sebuah merk Engineering Plastics yang sangat terpercaya kualitas nya dan telah digunakan di banyak negara.",
      "Jika tertarik untuk membeli produk ini silahkan hubungi ASIA POLYMER",
    ],
    filter: "cat-2",
    price: 1234567,
    min_purchase: 1,
    unit: "Role",
  },
  {
    slug: "epoxy",
    thumb: require("./../../images/product/product3.webp"),
    image: require("./../../images/product/product3.webp"),
    title: "Epoxy",
    description: [
      "Gabungan antara kain yang dicampur oleh resin, sangat baik sebagai isolator di suhu yang tinggi dan kondisi yang kering/basah.",
      {
        title: "Karakter :",
        detail: [
          "Kuat dan stabil terhadap suhu ekstrim",
          "Tahan tegangan listrik (isolator)",
          "Suhu ~ 140°C",
        ],
      },
      {
        title: "Aplikasi :",
        detail: ["Terminal board, komponen listrik dsb."],
      },
    ],
    filter: "cat-3",
    price: 1234567,
    min_purchase: 1,
    unit: "Role",
  },
  {
    slug: "polyetetrafluoroethylene",
    thumb: require("./../../images/product/product5.webp"),
    image: require("./../../images/product/product5.webp"),
    title: "Polyetetrafluoroethylene (PTFE)",
    description: [
      "Material plastik terlicin digolongannya. Dengan karakter ini PTFE sering menggantikan pelumas basah untuk lebih bersih dan tahan lama. PTFE tahan air dan suhu yang bervarian dan tersedia dengan beberapa modifikasi untuk aplikasi tertentu.",
      {
        title: "Modifikasi :",
        detail: [
          "PTFE Glass : untuk gesekan tinggi",
          "PTFE Bonze : untuk beban tinggi",
          "PTFE Carbon : untuk suhu tinggi",
        ],
      },
      {
        title: "Karakter :",
        detail: [
          "Tidak merambatkan api",
          "Food grade",
          "Isolator yang baik",
          "Sangat licin",
          "Tahan kimia",
          "Suhu -250°C ~ 260°C",
          "Tahan banting",
        ],
      },
      {
        title: "Aplikasi :",
        detail: [
          "Insulator, seal, bearing, chemical processing, aerospace, food processing dsb.",
        ],
      },
    ],
    filter: "cat-4",
    price: 1234567,
    min_purchase: 1,
    unit: "Role",
  },
  {
    slug: "polyvinylidene-fluoride-pvdf",
    thumb: require("./../../images/product/product4.webp"),
    image: require("./../../images/product/product4.webp"),
    title: "Polyvinylidene Fluoride (PVDF)",
    description: [
      "Salah satu dari keluarga fluoro polimer, dengan karekter tahan kimia, kaku untuk dibubut presisi dan ketahanan suhu yang cukup tinggi dibanding plastik lainnya.",
      {
        title: "Karakter :",
        detail: [
          "Tahan kimia",
          "Tahan ultraviolet",
          "Kaku dan keras",
          "Baik saat dibubut",
          "Tidak merambatkan api",
          "Suhu ~ 170°C",
        ],
      },
      {
        title: "Aplikasi :",
        detail: [
          "Industri kimia, semi konduktor, tangki kimia, nozzle, filter dsb.",
        ],
      },
    ],
    filter: "cat-5",
    price: 1234567,
    min_purchase: 1,
    unit: "Lembar",
  },
  {
    slug: "Polyetheretherketone-PEEK",
    thumb: require("./../../images/product/product6.webp"),
    image: require("./../../images/product/product6.webp"),
    title: "Polyetheretherketone (PEEK)",
    description: [
      `PEEK adalah salah satu plastik berkategori "high perfomance". Sangat kuat dan kaku, PEEK mempunyai kekuatan tensil terbaik diantara semua jenis plastik juga dapat digunakan dalam suhu 250 C secara berlanjut tanpa adanya perubahan karakter.`,
      {
        title: "Karakter :",
        detail: [
          "Tidak menyerap lembab",
          "Suhu ~ 250°C",
          "Tahan gesek dan aus",
          "Tahan kimia",
          "Mudah untuk dibubut dan berpresisi",
        ],
      },
      {
        title: "Aplikasi :",
        detail: [
          "Roller, parts otomotif, bushing, komponen konveyor, parts turbin, oil and gas seals dsb.",
        ],
      },
    ],
    filter: "cat-4",
    price: 1234567,
    min_purchase: 1,
    unit: "Role",
  },
  {
    slug: "polyurethane-PU",
    thumb: require("./../../images/product/product7.webp"),
    image: require("./../../images/product/product7.webp"),
    title: "Polyurethane (PU)",
    description: [
      `Kunci utama dari polyurethane (PU) adalah keberagaman dalam fungsi. PU sangat baik untuk segala aplikasi yang mengalami tekanan stres.`,
      {
        title: "Karakter :",
        detail: [
          "Tahan bentur",
          "Fleksibel",
          "Rekat",
          "Tahan aus",
          "Suhu ~ 60°C",
        ],
      },
      {
        title: "Aplikasi :",
        detail: ["Alas tahan bentur, dudukan, gasket dsb."],
      },
    ],
    filter: "cat-3",
    price: 1234567,
    min_purchase: 1,
    unit: "Role",
  },
  {
    slug: "polycarbonate-PC",
    thumb: require("./../../images/product/product8.webp"),
    image: require("./../../images/product/product8.webp"),
    title: "Polycarbonate (PC)",
    description: [
      `Tampilan transparan, lain diantara plastik lainnya. PC sangat dikenal sebagai bahan anti pecah dan sering dipakai sebagai tameng/pelindung pengganti kaca.`,
      {
        title: "Karakter :",
        detail: [
          "Mudah dibubut mesin",
          "Stabil dalam ukuran",
          "Tahan benturan",
          "Transparan",
          "Isolator yang baik",
          "Tahan ultraviolet",
          "Suhu ~ 100°C",
        ],
      },
      {
        title: "Aplikasi :",
        detail: ["Kanopi, roda gigi, model produk, dekotari dsb."],
      },
    ],
    filter: "cat-2",
    price: 1234567,
    min_purchase: 1,
    unit: "Role",
  },
  {
    slug: "mc-nylon",
    thumb: require("./../../images/product/product9.webp"),
    image: require("./../../images/product/product9.webp"),
    title: "MC NYLON",
    description: [
      "MC Nylon meningkatkan karakter nylon dengan sistem polimerisasi bahan dasar monomer Nylon melalui proses cetak atau extrusi. Tersedia beberapa tipe dengan modifier.",
      {
        title: "Karakter :",
        detail: [
          "Kuat untuk aplikai mekanik",
          "Tahan kimia",
          "Tahan aus",
          "Mudah untuk dibubut mesin",
          "Suhu -40°C ~ 100°C",
          "Nylon dapat menyerap lembab. Dalam kondisi basah, nylon bisa memuai sampai 2 % dari panjangnya.",
        ],
      },
      {
        title: "Aplikasi :",
        detail: [
          "Gigi roda, pulley, coupling, bearing, chain guide, roller dsb.",
        ],
      },
      {
        title: "Jenis :",
        detail: [
          "Moly : tahan gesek dan tekanan tinggi",
          "Oil : tahan abrasi tinggi (kecepatan tinggi, beban ringan)",
          "ESD : anti statik",
        ],
      },
    ],
    filter: "cat-1",
    price: 1234567,
    min_purchase: 1,
    unit: "Role",
  },
  {
    slug: "polyoxymethylene-pom",
    thumb: require("./../../images/product/product10.webp"),
    image: require("./../../images/product/product10.webp"),
    title: "Polyoxymethylene (POM)",
    description: [
      "Dipakai sebagai part yang memerlukan presisi dengan kekerasan dan kekakuan serta licin.",
      {
        title: "Karakter :",
        detail: [
          "Kuat untuk aplikasi mekanik",
          "Tahan kimia",
          "Tahan aus",
          "Mudah untuk dibubut mesin",
          "Stabil dalam ukuran",
          "Tidak menyerap lembab",
          "Suhu ~ 100°C",
        ],
      },
      {
        title: "Aplikasi :",
        detail: ["Roda gigi, bushing roller, bearing, washer dsb."],
      },
    ],
    filter: "cat-2",
    price: 1234567,
    min_purchase: 1,
    unit: "Role",
  },
  {
    slug: "pvc",
    thumb: require("./../../images/product/product11.webp"),
    image: require("./../../images/product/product11.webp"),
    title: "PVC",
    description: [
      "Engineering plastik terkenal dengan tahan kimia dan arus listrik.",
      {
        title: "Karakter :",
        detail: [
          "Baik untuk isolator listrik",
          "Kaku dan stabil",
          "Tahan kimia",
          "Bisa di welding",
        ],
      },
      {
        title: "Aplikasi :",
        detail: ["Dekorasi, tangki, box baterei, isolator dsb."],
      },
    ],
    filter: "cat-3",
    price: 1234567,
    min_purchase: 1,
    unit: "Role",
  },
];
