import React from "react";
import { NavLink } from "react-router-dom";
// import { FILTERS_PRODUCT } from "../../constant/FiltersProduct";
import { PRODUCTS } from "../../constant/product";

export const OurProduct = () => {
  return (
    <div className="section-full mobile-page-padding p-t80 p-b30 square_shape2 ">
      <div className="container">
        {/* TITLE START */}
        <div className="section-head">
          <div className="mt-separator-outer separator-left">
            <div className="mt-separator">
              <h2 className="text-uppercase sep-line-one ">
                <span className="font-weight-300 text-primary">Our</span>{" "}
                Product
              </h2>
            </div>
          </div>
        </div>
        {/* TITLE END */}
        <div className="section-content">
          <div className="portfolio-wrap row mfp-gallery product-stamp clearfix">
            {/* COLUMNS 1 */}
            {/* <div className="stamp col-md-3 col-sm-6 m-b30">
              <div className="bg-gray p-a30">
                <div className="filter-wrap">
                  <ul className="filter-navigation masonry-filter text-uppercase">
                    {FILTERS_PRODUCT.map((item, index) => (
                      <li key={index}>
                        <NavLink
                          to={"#"}
                          data-filter={item.filter}
                          data-hover={item.label}
                        >
                          {item.label}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div> */}
            {/* COLUMNS 2 */}
            {PRODUCTS.map((item, index) => (
              <div
                key={index}
                className={`${item.filter} masonry-item col-md-3 col-sm-6 m-b30`}
              >
                <div className="mt-box image-hover-block">
                  <div
                    className=""
                    style={{ width: "100%", aspectRatio: "1/1" }}
                  >
                    <img
                      src={item.image}
                      alt={item.title}
                      // style={{ width: "100%", aspectRatio: "1/1" }}
                      style={{
                        width: "100%",
                        aspectRatio: "1/1",
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                    />
                  </div>
                  <div className="mt-info  p-t20 text-white">
                    <h4 className="mt-tilte m-b10 m-t0">{item.title}</h4>
                    <p className="m-b0">{item.address}</p>
                  </div>
                  <NavLink to={`/product/${item.slug}`}></NavLink>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="hilite-title text-left p-l50 text-uppercase text-pop-up-top">
        <strong>Projects</strong>
      </div>
    </div>
  );
};
