import React, { Component } from "react";

export default class VisionAndMission extends Component {
  render() {
    return (
      <div className="section-content m-t50">
        <div className="section-head">
          {/* VISI */}
          <div className="mt-separator-outer separator-center">
            <div className="mt-separator">
              <h2 className="text-uppercase sep-line-one ">VISI</h2>
            </div>
            <div className="section-head m-b50 text-center">
              <p>
                MENJADI PERUSAHAAN YANG UNGGUL DAN TANGGUH DALAM BIDANG
                PENYEDIAAN MATERIAL YANG DIBUTUHKAN OLEH DUNIA INDUSTRI DI
                INDONESIA
              </p>
            </div>
          </div>

          {/* MISI */}
          <div className="mt-separator-outer separator-center">
            <div className="mt-separator">
              <h2 className="text-uppercase sep-line-one ">MISI</h2>
            </div>
            <div className="section-head m-b50 text-center">
              <p>
                perusahaan kami menyediakan produk yang bermutu dan harga yang
                kompetitif,serta menjadi vendor yang handal dan terpercaya bagi
                industri di Indonesia.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
