import React, { useMemo } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import { useParams } from "react-router-dom";
import Banner from "../Elements/Banner";
// import SimilarProjects from "../Elements/SimilarProjects";
// import ReactPlayer from "react-player";
import { PRODUCTS } from "../../constant/product";
import { ProductDetailFooterSosmed } from "../Common/ProductDetailFooterSosmed";
import { ReactTitle } from "react-meta-tags";
import { NavLink } from "react-router-dom";

const ProductDetail = () => {
  const { slug } = useParams();

  const isProduct = useMemo(() => {
    return PRODUCTS.find((product) => product.slug === slug);
  }, [slug]);

  return (
    <React.Fragment>
      <ReactTitle
        title={`${isProduct.title} | Asia Polymer Jakarta - Resmi Rochling & Engineering Plastic`}
      />
      <Header />
      {isProduct && (
        <>
          <div className="page-content">
            <Banner
              title={isProduct.title}
              pagename={isProduct.title}
              bgimage={isProduct.image}
            />

            {/* SECTION CONTENT START */}
            <div className="section-full p-tb80 inner-page-padding">
              <div className="container">
                <div className="project-detail-outer">
                  {/* <div className="m-b0">
                <div className="row">
                  <div className="col-md-4 col-sm-4">
                    <div className="project-detail-pic m-b30">
                      <div className="mt-media">
                        <img
                          src={require("./../../images/projects/portrait/pic7.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="project-detail-pic m-b30">
                      <div className="mt-media">
                        <img
                          src={require("./../../images/projects/portrait/pic4.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div className="project-detail-pic m-b30">
                      <div className="mt-media">
                        <img
                          src={require("./../../images/projects/portrait/pic5.jpg")}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
                  <div className="m-b30">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="mt-box">
                          <button
                            type="button"
                            className=""
                            data-toggle="modal"
                            data-target="#myModal3"
                            style={{ width: "100%" }}
                          >
                            <div className="mt-thum-bx mt-img-overlay1 mt-img-effect yt-thum-box">
                              <img
                                src={isProduct.image}
                                alt={isProduct.title}
                                style={{
                                  aspectRatio: "1/1",
                                  objectPosition: "center",
                                  objectFit: "cover",
                                }}
                              />
                              {/* <i className="icon fa fa-play" />
                              <span className="ripple" /> */}
                            </div>
                          </button>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="product-block">
                          <div className="row">
                            <div className="col-md-12 m-b10">
                              <h4 className="text-uppercase font-weight-600 m-b10">
                                Nama
                              </h4>
                              <p>{isProduct.title}</p>
                            </div>
                            <div className="col-md-12 m-b10">
                              <h4 className="text-uppercase font-weight-600 m-b10">
                                Harga
                              </h4>
                              {/* <p>Rp. {FormatCurrencyId(isProduct.price)}</p> */}
                              <NavLink
                                target="_blank"
                                className="m-b15 site-button btn-effect m-r15 text-uppercase"
                                to={`https://api.whatsapp.com/send?phone=+6281333330456&text=Halo Asia Polymer, kami ingin mendapatkan informasi terkait produk ${isProduct.title}`}
                              >
                                Minta Penawaran
                              </NavLink>
                            </div>
                            <div className="col-md-12 m-b10">
                              <h4 className="text-uppercase font-weight-600 m-b10">
                                Satuan
                              </h4>
                              <p>{isProduct.unit}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="project-detail-containt">
                    <div className="bg-white text-black">
                      <h3 className="font-weight-600">{isProduct.title}</h3>
                      {isProduct.description.map((item, index) => (
                        <div key={index}>
                          {typeof item === "string" ? (
                            <p>{item}</p>
                          ) : (
                            <>
                              <h4>{item.title}</h4>
                              <p className="m-l30">
                                {item.detail.map((desc, i) => (
                                  <span key={i}>
                                    {i > 0 && <br />}
                                    {desc}
                                  </span>
                                ))}
                              </p>
                            </>
                          )}
                        </div>
                      ))}
                      <div className="m-b0">
                        <div className="mt-divider divider-1px  bg-black">
                          <i className="icon-dot c-square" />
                        </div>
                      </div>
                      <ProductDetailFooterSosmed />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* SECTION CONTENT END  */}
            {/* <SimilarProjects /> */}
          </div>

          <div className="modal fade" id="myModal3" role="dialog">
            <div className="modal-dialog">
              <div className="modal-content">
                {/* <ReactPlayer url="https://www.youtube.com/watch?v=s3A7AK1K2hc" /> */}
                <img src={isProduct.image} alt={isProduct.title} />
              </div>
            </div>
          </div>
        </>
      )}

      <Footer />
    </React.Fragment>
  );
};

export default ProductDetail;
