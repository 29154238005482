import React from "react";
import { NavLink } from "react-router-dom";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
// import Banner from "./../Elements/Banner";
// import { FILTERS_PRODUCT } from "../../constant/FiltersProduct";
import { PRODUCTS } from "../../constant/product";
import { FormatCurrencyId } from "../../utils/formatCurrency";
import { TruncateText } from "../../helpers/format";

// var bnrimg = require("./../../images/banner/1.jpg");

class ProjectMasonary extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/masonary.js");
  }

  render() {
    return (
      <>
        <Header />
        <div className="page-content">
          {/* <Banner
            title="Creating quality urban lifestyles, building stronger communities."
            pagename="Project Masonry"
            bgimage={bnrimg}
          /> */}

          {/* SECTION CONTENT START */}
          <div className="section-full p-tb80 bg-gray inner-page-padding">
            {/* GALLERY CONTENT START */}
            <div className="container-fluid">
              {/* PAGINATION START */}
              {/* <div className="filter-wrap p-b30 text-center">
                <ul className="filter-navigation inline-navigation masonry-filter link-style  text-uppercase">
                  {FILTERS_PRODUCT.map((item, index) => (
                    <li
                      key={index}
                      className={item.label === "All" ? "active" : ""}
                    >
                      <NavLink
                        to={"#"}
                        data-filter={item.filter}
                        data-hover={item.label}
                      >
                        {item.label}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div> */}
              {/* PAGINATION END */}
              {/* GALLERY CONTENT START */}
              <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                {PRODUCTS.map((item, index) => (
                  <div
                    key={index}
                    className={`${item.filter} masonry-item col-lg-3  col-md-4 col-sm-6 m-b30`}
                  >
                    <div className="image-effect-one hover-shadow">
                      <img
                        src={item.thumb}
                        alt={item.title}
                        style={{
                          width: "100%",
                          aspectRatio: "1/1",
                          objectFit: "cover",
                          objectPosition: "center",
                        }}
                      />
                      <div className="figcaption">
                        <h4>{TruncateText(item.title, 20)}</h4>
                        <p>Rp. {FormatCurrencyId(item.price)}</p>
                        <NavLink to={`/product/${item.slug}`}>
                          <i className="link-plus bg-primary" />
                          {/* <div
                            style={{
                              position: "absolute",
                              bottom: 0,
                              right: 0,
                              width: "58px",
                              height: "100%",
                              fontSize: "18px",
                              textAlign: "center",
                              backgroundColor: "#F5BF23",
                              color: "#fff",
                              display: "grid",
                              placeItems: "center",
                            }}
                          >
                            <i className="fa fa-eye" />
                          </div> */}
                        </NavLink>
                      </div>
                      <a className="mfp-link" href={item.image}>
                        <i className="fa fa-arrows-alt" />
                      </a>
                    </div>
                  </div>
                ))}
              </div>
              {/* GALLERY CONTENT END */}
              <div className="m-t50 text-center">
                <NavLink to={"#"} className="site-button btn-effect">
                  Load More
                </NavLink>
              </div>
            </div>
            {/* GALLERY CONTENT END */}
          </div>
          {/* SECTION CONTENT END  */}
        </div>

        <Footer />
      </>
    );
  }
}

export default ProjectMasonary;
