import React from "react";
import ServicesAndSpecialized from "./ServicesAndSpecialized";

var img1 = require("./../../images/background/ptn-1.png");

class Specialization4 extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full p-tb80 bg-white bg-repeat square_shape2 inner-page-padding"
          style={{ backgroundImage: "url(" + img1 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="mt-separator-outer separator-left">
                <div className="mt-separator">
                  <h2 className="text-uppercase sep-line-one ">
                    <span className="font-weight-300 text-primary">Our</span>{" "}
                    Speciallization
                  </h2>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <ServicesAndSpecialized />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Specialization4;
